.admin-header {
  display: grid;
  grid-template-columns: 1fr 960px 1fr;
  background: black;
  height: 100px;
  color: white;
}

.admin-header-logo {
  grid-column: 2;
  height: 75px;
  margin: 10px;
}

.admin-body {
  display: grid;
  grid-template-columns: 1fr 960px 1fr;
  grid-template-rows: 100px auto;
  background-image: linear-gradient(-152deg, #f47070 29%, #fd996b 100%);
}

.admin-body p {
  grid-column: 2;
  text-align: center;
  color: white;
  justify-self: center;
  align-self: center;
}

.admin-form {
  grid-column: 2;
  grid-row: 2;
  height: 800px;
  align-self: center;
  justify-self: center;
}

.admin-form input {
  height: 40px;
  width: 400px;
  font-family: "Roboto";
  font-size: 25px;
  border-radius: 10px;
  background: transparent;
  border: solid white 2px;
  padding: 5px;
  color: white;
}

.admin-form input:focus {
  outline-width: 0;
}

button {
  padding: 7px;
  margin-top: 10px;
  border: solid white 2px;
  border-radius: 10px;
  align-self: center;
  justify-self: center;
  background: transparent;
  font-family: "Roboto";
  text-decoration: none;
  color: white;
}

button:hover {
  background: black;
}

.admin-body h2 {
  grid-column: 2;
  grid-row: 2;
  justify-self: center;
  margin-top: 120px;
  font-family: "Roboto";
  color: white;
  font-weight: 100;
}

.admin-footer {
  display: grid;
  grid-template-columns: 1fr 960px 1fr;
  background: black;
  height: 100px;
}

.admin-footer-logo {
  grid-column: 2;
  height: 75px;
  margin: 10px;
  align-self: center;
  justify-self: center;
}
