@import url("https://fonts.googleapis.com/css?family=Roboto");

.header {
  margin: auto;
  width: 960px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: black;
  height: 100px;
  margin-bottom: -35px;
}

@media only screen and (max-width: 960px) {
  .header {
    width: auto;
  }
}

.header-logo {
  align-self: center;
  height: 30px;
  margin: 10px;
}

.header h1 {
  color: white;
  font-family: "Roboto";
  align-self: center;
  justify-self: right;
  font-weight: 100;
  font-size: 20px;
  font-style: italic;
}

.body {
  display: grid;
  grid-template-columns: 1fr 960px 1fr;
  grid-template-rows: 70px auto;
  background-color: #000;
  height: 2500px;
}

.tabs {
  grid-column: 2;
  justify-self: start;
  align-self: end;
}

.menu-button {
  padding: 10px 15px 15px 15px;
  margin-bottom: 15px;
  border: solid white 2px;
  border-radius: 10px;
  color: white;
  font-size: 20px;
  align-self: center;
  justify-self: center;
  font-family: "Roboto";
  text-decoration: none;
}

.menu-button.active {
  background-color: #939393;
}

.report {
  grid-column: 2;
  overflow: hidden;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 960px 1fr;
  background: black;
  height: 100px;
}

.footer-logo {
  grid-column: 2;
  height: 75px;
  margin: 10px;
  align-self: center;
  justify-self: center;
}
