@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  background-color: black;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  margin: auto;
  width: 960px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: black;
  height: 100px;
  margin-bottom: -35px;
}

@media only screen and (max-width: 960px) {
  .header {
    width: auto;
  }
}

.header-logo {
  align-self: center;
  height: 30px;
  margin: 10px;
}

.header h1 {
  color: white;
  font-family: "Roboto";
  align-self: center;
  justify-self: right;
  font-weight: 100;
  font-size: 20px;
  font-style: italic;
}

.body {
  display: grid;
  grid-template-columns: 1fr 960px 1fr;
  grid-template-rows: 70px auto;
  background-color: #000;
  height: 2500px;
}

.tabs {
  grid-column: 2;
  justify-self: start;
  align-self: end;
}

.menu-button {
  padding: 10px 15px 15px 15px;
  margin-bottom: 15px;
  border: solid white 2px;
  border-radius: 10px;
  color: white;
  font-size: 20px;
  align-self: center;
  justify-self: center;
  font-family: "Roboto";
  text-decoration: none;
}

.menu-button.active {
  background-color: #939393;
}

.report {
  grid-column: 2;
  overflow: hidden;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 960px 1fr;
  background: black;
  height: 100px;
}

.footer-logo {
  grid-column: 2;
  height: 75px;
  margin: 10px;
  align-self: center;
  justify-self: center;
}

.admin-header {
  display: grid;
  grid-template-columns: 1fr 960px 1fr;
  background: black;
  height: 100px;
  color: white;
}

.admin-header-logo {
  grid-column: 2;
  height: 75px;
  margin: 10px;
}

.admin-body {
  display: grid;
  grid-template-columns: 1fr 960px 1fr;
  grid-template-rows: 100px auto;
  background-image: -webkit-linear-gradient(242deg, #f47070 29%, #fd996b 100%);
  background-image: linear-gradient(-152deg, #f47070 29%, #fd996b 100%);
}

.admin-body p {
  grid-column: 2;
  text-align: center;
  color: white;
  justify-self: center;
  align-self: center;
}

.admin-form {
  grid-column: 2;
  grid-row: 2;
  height: 800px;
  align-self: center;
  justify-self: center;
}

.admin-form input {
  height: 40px;
  width: 400px;
  font-family: "Roboto";
  font-size: 25px;
  border-radius: 10px;
  background: transparent;
  border: solid white 2px;
  padding: 5px;
  color: white;
}

.admin-form input:focus {
  outline-width: 0;
}

button {
  padding: 7px;
  margin-top: 10px;
  border: solid white 2px;
  border-radius: 10px;
  align-self: center;
  justify-self: center;
  background: transparent;
  font-family: "Roboto";
  text-decoration: none;
  color: white;
}

button:hover {
  background: black;
}

.admin-body h2 {
  grid-column: 2;
  grid-row: 2;
  justify-self: center;
  margin-top: 120px;
  font-family: "Roboto";
  color: white;
  font-weight: 100;
}

.admin-footer {
  display: grid;
  grid-template-columns: 1fr 960px 1fr;
  background: black;
  height: 100px;
}

.admin-footer-logo {
  grid-column: 2;
  height: 75px;
  margin: 10px;
  align-self: center;
  justify-self: center;
}

